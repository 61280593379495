import useStatistics from "@/hooks/useStatistics";
import { StatisticHexagon } from "@/ui/StatisticHexagon";
import cn from "classnames";
import { Container, Stack } from "react-bootstrap";

import classes from "./styles.module.scss";

export default function ({ className = "py-5 pb-md-0 pb-xl-7", theme = "light" }) {
  const statistics = useStatistics();

  return (
    <div className={cn(className, classes.StatisticsHexagons)} data-bs-theme={theme}>
      <Container fluid="xxl">
        <Stack className="flex-row flex-wrap justify-content-center mb-6 mb-md-0">
          {statistics.map((statistic, idx) => (
            <div key={idx} className="flex-grow-1 p-1 p-md-3">
              <StatisticHexagon className="mx-auto" {...statistic} />
            </div>
          ))}
        </Stack>
      </Container>
    </div>
  );
}
